
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import ReportBuilderOptions from '@/components/ReportBuilder/ReportBuilder.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import ReportBuilderFilter from '@/components/ReportBuilder/ReportBuilderFilter.vue'
import WebMessage from '@/models/WebMessage'
import { groupBy, startCase } from 'lodash'
import User from '@/models/User'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import ReportBuilder from '@/models/ReportBuilder'
import DataTable from '@/components/DataTable/index.vue'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import ReportViewer from '@/pages/ReportBuilder/ReportViewer.vue'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import ChartBuilder from '@/models/Charts/ChartBuilder'
import ChartError from '@/models/interface/ChartError'
import moment from 'moment'

@Component({
  components: {
    FormInput,
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    ReportBuilderOptions,
    FooterNav,
    ReportBuilderFilter,
    CheckboxInput,
    DataTable,
    ReportViewer,
    CustomIcon,
  },
})
export default class ReportBuilderForm extends ViewModel {
  @Ref() public validator!: any

  @Ref() readonly saveForm!: HTMLFormElement

  @Ref() readonly settingsWidget!: Widget

  @Prop({ required: true })
  public value!: ReportBuilder

  @Prop({ required: false })
  public standalone!: boolean

  @Prop({ required: false })
  public dontLoadOnMounted!: boolean

  public raw_data: any | null = null

  public selected_report_type: string = 'delivery'

  public show_filters: boolean = false

  public busy: boolean = false

  public search_for: any = ''

  public custom_to_options: any = []

  public to_options: any = []

  public report_types: any = []

  public loading: boolean = true

  public loaded_settings: any = []

  public fields: object[] = []

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public ready = false

  public ran_report = false

  public report_channel: any = null

  public view_as: string = 'table:table'

  public get active_date_settings() {
    if (this.local_value.period_filter === 'none') {
      return this.selected_settings.filter_dates[0]
    }

    let ret = this.selected_settings.filter_dates.find(
      (d: any) => d.value === this.local_value.period_filter,
    )

    if (!ret) {
      this.local_value.period_filter = 'none'
      return this.selected_settings.filter_dates[0]
    }

    return ret
  }

  public get local_value() {
    return this.value
  }

  public set local_value(val) {
    this.$emit('input', val)
  }

  public get view_mode() {
    let ret = this.view_as.split(':')

    return { type: ret[0], subtype: ret[1] ?? '' }
  }

  public get active_view() {
    return this.view_options.find((view: any) => view.value === this.view_as)
  }

  public isViewCompatible(view: any) {
    if (
      !this.raw_data
      || !this.raw_data.result
      || this.raw_data.result.length === 0
    ) {
      return { compatible: false, reason: 'No data' }
    }

    if (view.value === 'table:table') {
      return {
        compatible: true,
      }
    }

    const type = view.value.split(':')[1]

    try {
      ChartBuilder.isCompatible(type, this.raw_data)
    } catch (e) {
      if (e instanceof ChartError) {
        return {
          compatible: false,
          reason: e.message,
        }
      }
      return {
        compatible: false,
        reason: 'Unknown error',
      }
    }

    return {
      compatible: true,
    }
  }

  public view_options: any[] = [
    { name: 'Table', value: 'table:table', icon: 'table' },
    { name: 'Bar Chart', value: 'chart:bar', icon: 'chart-bar' },
    { name: 'Line Chart', value: 'chart:line', icon: 'chart-line' },
    // { name: 'Area Chart', value: 'chart:area', icon: 'chart-area' },
    { name: 'Pie Chart', value: 'chart:pie', icon: 'chart-pie' },
    { name: 'Radial Chart', value: 'chart:radialBar', customIcon: 'radial' },
    {
      name: 'Gauge Chart',
      value: 'chart:gauge',
      customIcon: 'gauge',
    },
    { name: 'Treemap Chart', value: 'chart:treemap', customIcon: 'treemap' },
    { name: 'Heatmap Chart', value: 'chart:heatmap', icon: 'fire-alt' },
    { name: 'Funnel Chart', value: 'chart:funnel', icon: 'filter' },
    { name: 'Radar Chart', value: 'chart:radar', customIcon: 'radar' },
  ]

  public modal: any = {
    delete: false,
    send: false,
    filter: false,
    save: false,
  }

  public get all_active_options() {
    return [
      ...this.local_value.dimensions,
      ...this.local_value.metrics,
      ...this.local_value.selected_filters,
    ]
  }

  public get filter_count() {
    let count = 0

    for (let key in this.local_value.filters) {
      if (this.local_value.filters[key].length) {
        count += this.local_value.filters[key].length
      }
    }

    return count
  }

  public period_options: object[] = [
    { name: 'None', value: 'none' },
    { name: 'Daily (Everyday)', value: 'daily' },
    { name: 'Weekly (Every Monday)', value: 'weekly' },
    { name: 'Monthly (1st day of Month)', value: 'monthly' },
  ]

  public get report_type_list() {
    if (!this.report_types.length) return []

    let grouped = groupBy(this.report_types, 'group')

    return Object.keys(grouped).map(key => ({
      group: key,
      content: grouped[key].map(item => ({
        name: item.name,
        value: item.value,
      })),
    }))
  }

  public get email_picker_options() {
    return [...this.to_options, ...this.custom_to_options]
  }

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.custom_to_options.push(tag)
      if (!this.local_value.to || !this.local_value.to.length) this.local_value.to = []
      this.local_value.to.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public handleSaveOk() {
    this.saveForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.modal.save = false
      this.saveReportSettings()
    })
  }

  public populateEmailOptions() {
    if (this.local_value.to.length > 0) {
      this.local_value.to.forEach((item: string) => {
        if (!this.custom_to_options.some((o: any) => o.value == item)) {
          this.custom_to_options.push({
            name: item,
            value: item,
          })
        }
      })
      this.busy = true
      User.searchOptions({
        value: this.custom_to_options,
      }).then(response => {
        this.to_options = response
        this.cleanCustomToList()
        this.busy = false
      })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
    }).then(response => {
      this.to_options = response.map((o: any) => ({
        name: o.name,
        value: o.detail,
      }))
      this.cleanCustomToList()
      this.busy = false
    })
  }

  public cleanCustomToList() {
    if (this.custom_to_options.length > 0) {
      this.custom_to_options = this.custom_to_options.filter((o: any) => {
        if (this.local_value.to.includes(o.value)) return true
        return !this.to_options.some(
          (so: any) => so.value.toLowerCase() == o.value.toLowerCase(),
        )
      })
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public get selected_settings() {
    if (this.loaded_settings.length) {
      let settings = this.loaded_settings.find(
        (settings: any) => settings.value === this.local_value.type,
      )
      if (settings) {
        return settings
      }
    }

    return {
      dimension_options: [],
      dimensions: [],
      metric_options: [],
      metrics: [],
    }
  }

  public mounted() {
    this.loadReportSettings().then(() => {
      if (this.standalone === false) {
        this.loading = false
        this.ready = true
        return
      }
      // Select first report where selected is true, if none, use first report
      let selected = this.report_types.find((r: any) => r.selected === true)

      if (!selected) {
        selected = this.report_types[0]
      }

      this.local_value.formType = selected.value

      this.initReport()
      this.loading = false
      this.ready = true
    })
  }

  /**
   * Initialize report settings selecting default dimensions and metrics
   * based on the selected report type. If no dimensions or metrics are
   * available, show the filters, otherwise hide them.
   */
  public initReport() {
    // Select initial fields
    let dimensions: string[] = []
    let metrics: string[] = []
    this.ran_report = false

    let order: string[] = []
    const processor = (target: string, v: any) => {
      if (v.options && v.options.length > 0) {
        v.options.forEach((o: any) => {
          if (o.selected) {
            if (target === 'dimensions') {
              dimensions.push(o.value)
            } else {
              metrics.push(o.value)
            }

            order[o.selected - 1] = o.value
          }
        })
      } else if (v.selected) {
        if (target === 'dimensions') {
          dimensions.push(v.value)
        } else {
          metrics.push(v.value)
        }
        order[v.selected - 1] = v.value
      }
    }

    if (this.selected_settings.dimensions) {
      this.local_value.dimensions = this.selected_settings.dimensions.forEach(
        (v: any) => processor('dimensions', v),
      )
    }

    if (this.selected_settings.metrics) {
      this.local_value.metrics = this.selected_settings.metrics.forEach(
        (v: any) => processor('metrics', v),
      )
    }

    // Sort dimensions and metrics by order
    dimensions = dimensions.sort(
      (a: any, b: any) => order.indexOf(a) - order.indexOf(b),
    )
    metrics = metrics.sort(
      (a: any, b: any) => order.indexOf(a) - order.indexOf(b),
    )

    if (!this.selected_settings.dimensions && !this.selected_settings.metrics) {
      this.show_filters = true
    } else {
      this.show_filters = false
    }

    this.selected_settings.filter_dates.forEach((d: any) => {
      if (d.selected) {
        this.local_value.period_filter = d.value
      }
    })
    // console.log('the dates', this.local_value.period_picker)
    // TODO continue here, investigating why period_picker is losing its class reference
    this.local_value.dimensions = dimensions
    this.local_value.metrics = metrics
  }

  public async loadReportSettings() {
    return this.local_value.loadReportSettings().then(response => {
      const types = response.data.map((report: any) => ({
        name: report.name,
        value: report.value,
        group: report.group,
        show: report.show,
        selected: report.selected,
      }))

      this.report_types = types

      this.loaded_settings = response.data
      this.$emit('settingsChange', response.data)
      return response.data
    })
  }

  public saveReportSettings() {
    this.loading = true
    this.local_value.save().then(() => {
      this.loading = false
    })
  }

  public download() {
    this.loading = true
    WebMessage.success(
      'Your report is being generated. Please wait for the download to start.',
    )
    this.local_value.download().then(() => {
      this.loading = false
    })
  }

  public checkDateFilters() {
    if (this.active_date_settings.type == 'year') {
      this.local_value.period_picker.start = moment(
        this.local_value.period_picker.start,
      )
        .startOf('year')
        .format('YYYY-MM-DD')
    }
    if (!this.active_date_settings.range) {
      this.local_value.period_picker.end = moment(
        this.local_value.period_picker.start,
      )
        .endOf(this.active_date_settings.type == 'year' ? 'year' : 'month')
        .format('YYYY-MM-DD')
      this.local_value.period_picker.shortcut = null
    }
  }

  public run() {
    this.checkDateFilters()
    if (this.selected_settings && this.selected_settings.export_only) {
      this.download()
      return
    }

    this.loading = true
    this.settingsWidget.collapseWidget(true)
    this.local_value
      .runReport()
      .then(response => {
        if (response.data.result) {
          this.raw_data = response.data

          this.loading = false
          this.ran_report = true
        }
      })
      .catch(error => {
        this.loading = false
        this.settingsWidget.collapseWidget(false)
      })
  }

  public normalizeCode(code: string) {
    return startCase(code.replace(/_/g, ' '))
  }
}
