
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import ReportBuilderOptions from '@/components/ReportBuilder/ReportBuilder.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import ReportBuilderFilter from '@/components/ReportBuilder/ReportBuilderFilter.vue'
import WebMessage from '@/models/WebMessage'
import { groupBy, startCase } from 'lodash'
import User from '@/models/User'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import ReportBuilder from '@/models/ReportBuilder'
import DataTable from '@/components/DataTable/index.vue'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import ReportViewer from '@/pages/ReportBuilder/ReportViewer.vue'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import ChartBuilder from '@/models/Charts/ChartBuilder'
import ChartError from '@/models/interface/ChartError'
import ReportBuilderForm from '@/pages/ReportBuilder/ReportBuilderForm.vue'
import moment from 'moment'

@Component({
  components: {
    FormInput,
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    ReportBuilderOptions,
    FooterNav,
    ReportBuilderFilter,
    CheckboxInput,
    DataTable,
    ReportViewer,
    CustomIcon,
    ReportBuilderForm,
  },
})
export default class ReportBuilderEdit extends ViewModel {
  @Ref() public validator!: any

  @Ref() readonly saveForm!: HTMLFormElement

  @Ref() readonly settingsWidget!: Widget

  @Prop({ default: false }) public formMode!: boolean

  @Prop({ default: '' }) public id!: string

  public report: ReportBuilder = new ReportBuilder()

  public raw_data: any | null = null

  public selected_report_type: string = 'delivery'

  public show_filters: boolean = false

  public busy: boolean = false

  public search_for: any = ''

  public custom_to_options: any = []

  public to_options: any = []

  public report_types: any = []

  public loading: boolean = true

  public loaded_settings: any = []

  public fields: object[] = []

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public ready = false

  public ran_report = false

  public report_channel: any = null

  public view_as: string = 'table:table'

  public standalone: boolean = false

  public get view_mode() {
    let ret = this.view_as.split(':')

    return { type: ret[0], subtype: ret[1] ?? '' }
  }

  public get active_date_settings() {
    if (this.report.period_filter === 'none') {
      return this.selected_settings.filter_dates[0]
    }

    return this.selected_settings.filter_dates.find(
      (d: any) => d.value === this.report.period_filter,
    )
  }

  public get active_view() {
    return this.view_options.find((view: any) => view.value === this.view_as)
  }

  public onSettingsChange(data: any) {
    this.loaded_settings = data
  }

  public isViewCompatible(view: any) {
    if (!this.raw_data || !this.raw_data.result || this.raw_data.result.length === 0) {
      return { compatible: false, reason: 'No data' }
    }

    if (view.value === 'table:table') {
      return {
        compatible: true,
      }
    }

    const type = view.value.split(':')[1]

    try {
      ChartBuilder.isCompatible(type, this.raw_data)
    } catch (e) {
      if (e instanceof ChartError) {
        return {
          compatible: false,
          reason: e.message,
        }
      }
      return {
        compatible: false,
        reason: 'Unknown error',
      }
    }

    return {
      compatible: true,
    }
  }

  public view_options: any[] = [
    { name: 'Table', value: 'table:table', icon: 'table' },
    { name: 'Bar Chart', value: 'chart:bar', icon: 'chart-bar' },
    { name: 'Line Chart', value: 'chart:line', icon: 'chart-line' },
    // { name: 'Area Chart', value: 'chart:area', icon: 'chart-area' },
    { name: 'Pie Chart', value: 'chart:pie', icon: 'chart-pie' },
    { name: 'Radial Chart', value: 'chart:radialBar', customIcon: 'radial' },
    {
      name: 'Gauge Chart',
      value: 'chart:gauge',
      customIcon: 'gauge',
    },
    { name: 'Treemap Chart', value: 'chart:treemap', customIcon: 'treemap' },
    { name: 'Heatmap Chart', value: 'chart:heatmap', icon: 'fire-alt' },
    { name: 'Funnel Chart', value: 'chart:funnel', icon: 'filter' },
    { name: 'Radar Chart', value: 'chart:radar', customIcon: 'radar' },
  ]

  public modal: any = {
    delete: false,
    send: false,
    filter: false,
    save: false,
  }

  public get all_active_options() {
    return [...this.report.dimensions, ...this.report.metrics, ...this.report.selected_filters]
  }

  public get filter_count() {
    let count = 0

    for (let key in this.report.filters) {
      if (this.report.filters[key].length) {
        count += this.report.filters[key].length
      }
    }

    return count
  }

  public period_options: object[] = [
    { name: 'None', value: 'none' },
    { name: 'Daily (Everyday)', value: 'daily' },
    { name: 'Weekly (Every Monday)', value: 'weekly' },
    { name: 'Weekly (Every Friday)', value: 'weekly-friday' },
    { name: 'Monthly (1st day of Month)', value: 'monthly' },
  ]

  public get report_type_list() {
    if (!this.report_types.length) return []

    let grouped = groupBy(this.report_types, 'group')

    return Object.keys(grouped).map(key => ({
      group: key,
      content: grouped[key].map(item => ({
        name: item.name,
        value: item.value,
      })),
    }))
  }

  public get email_picker_options() {
    return [...this.to_options, ...this.custom_to_options]
  }

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.custom_to_options.push(tag)
      if (!this.report.to || !this.report.to.length) this.report.to = []
      this.report.to.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public handleSaveOk() {
    this.saveForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.checkDateFilters()
      this.modal.save = false
      this.saveReportSettings()
    })
  }

  public populateEmailOptions() {
    if (this.report.to.length > 0) {
      this.report.to.forEach((item: String) => {
        if (!this.custom_to_options.some((o: any) => o.value == item)) {
          this.custom_to_options.push({
            name: item,
            value: item,
          })
        }
      })
      this.busy = true
      User.searchOptions({
        value: this.custom_to_options,
      }).then(response => {
        this.to_options = response
        this.cleanCustomToList()
        this.busy = false
      })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
    }).then(response => {
      this.to_options = response.map((o: any) => ({
        name: o.name,
        value: o.detail,
      }))
      this.cleanCustomToList()
      this.busy = false
    })
  }

  public cleanCustomToList() {
    if (this.custom_to_options.length > 0) {
      this.custom_to_options = this.custom_to_options.filter((o: any) => {
        if (this.report.to.includes(o.value)) return true
        return !this.to_options.some((so: any) => so.value.toLowerCase() == o.value.toLowerCase())
      })
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public get selected_settings() {
    if (this.loaded_settings.length) {
      let settings = this.loaded_settings.find(
        (settings: any) => settings.value === this.report.type,
      )
      if (settings) {
        return settings
      }
    }

    return {
      dimension_options: [],
      dimensions: [],
      metric_options: [],
      metrics: [],
    }
  }

  public mounted() {
    this.registerEvent()
    const { query } = this.$route
    let clone = false
    let target = this.id

    if (query.from && typeof query.from === 'string') {
      clone = true
      target = query.from
    }

    if (target) {
      ReportBuilder.find(target).then(o => {
        if (o instanceof ReportBuilder) {
          this.report = ReportBuilder.toObject(o)
          if (clone) {
            this.report.id = null
            this.report.name += ' (Copy)'
          }
        }

        this.populateEmailOptions()
        this.loading = false
        this.ready = true

        if (query.run && query.run == '1') {
          setTimeout(() => {
            this.run()
          }, 250)
        }
      })

      return
    }

    this.standalone = true

    this.loading = false
    this.ready = true
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
  }

  /**
   * Capture the paste event and get the data
   */
  private registerEvent() {
    this.report_channel = Vue.prototype.$echo
      .private(`user.${this.user!.id}`)
      .listen('AsyncReportReady', (e: any) => {
        const instance_id = getModule(SystemtModule)._uuid

        if (instance_id && instance_id !== e.instance_id) return

        if (!e.report_hash) {
          this.loading = false

          return
        }

        ReportBuilder.getReport(e.report_hash)
          .then(response => {
            if (response.data.result) this.raw_data = response.data
            this.loading = false
            this.ran_report = true
          })
          .catch(error => {
            this.loading = false
          })
      })
  }

  /**
   * Clear paste event to prevent triggering the event outside the page
   */
  private unregisterEvent() {
    Vue.prototype.$echo.private(`user.${this.user!.id}`).stopListening('AsyncReportReady')
  }

  public saveReportSettings() {
    this.loading = true
    this.report.save().then(() => {
      this.loading = false
      this.$router.push({ name: 'report-builders' })
    })
  }

  public download() {
    this.loading = true
    WebMessage.success('Your report is being generated. Please wait for the download to start.')
    this.report.download().then(() => {
      this.loading = false
    })
  }

  public checkDateFilters() {
    if (!this.active_date_settings) {
      return
    }
    if (this.active_date_settings.type == 'year') {
      this.report.period_picker.start = moment(this.report.period_picker.start)
        .startOf('year')
        .format('YYYY-MM-DD')
    }
    if (!this.active_date_settings.range) {
      this.report.period_picker.end = moment(this.report.period_picker.start)
        .endOf(this.active_date_settings.type == 'year' ? 'year' : 'month')
        .format('YYYY-MM-DD')
      this.report.period_picker.shortcut = null
    }
  }

  public run() {
    this.checkDateFilters()

    if (this.selected_settings && this.selected_settings.export_only) {
      this.download()
      return
    }

    this.loading = true
    this.settingsWidget.collapseWidget(true)
    this.report
      .runReport()
      .then(response => {
        if (response.data.result) {
          this.raw_data = response.data

          this.loading = false
          this.ran_report = true
        }
      })
      .catch(error => {
        this.loading = false
        this.settingsWidget.collapseWidget(false)
      })
  }

  public normalizeCode(code: string) {
    return startCase(code.replace(/_/g, ' '))
  }
}
