import ChartInterface from './ChartInterface'
import ChartBuilderHelper from './ChartBuilderHelper'
import { buildColorPool, formatText } from '../Util'
import ReportBuilderResponse from '../interface/ReportBuilderResponse'
import ChartError from '../interface/ChartError'

export default class BarChart extends ChartBuilderHelper implements ChartInterface {
  public static create(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
    check_only = false,
  ): ChartInterface {
    const ret = new this()

    ret.source_data = data
    ret.sub_type = type
    ret.settings = { ...ret.settings, ...settings }

    if (type === 'funnel') {
      ret.settings.isFunnel = true
      ret.sub_type = 'bar'
    }

    if (type === 'radar') {
      ret.settings.legend = true
    }

    ret.process(check_only)

    return ret
  }

  public static isCompatible(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
  ): boolean {
    this.create(type, data, settings, true)

    return true
  }

  protected settings: any = {
    zoom: false,
    animations: true,
    legend: false,
    disable_abbreviate: false,
    stacked: false,
    toolbar: false,
    isFunnel: false,
  }

  public labels: string[] = []

  public type: string = 'apex'

  public sub_type: string = 'bar'

  public groups: string[] = []

  public series: any[] = []

  public colors: string[] = []

  public total: number = 0

  public yaxis: any[] = []

  public stroke: number[] = []

  public get options(): any {
    const stacked = this.settings.stacked

    let ret: any = {
      chart: {
        type: this.sub_type,
        stacked,
        height: 'auto',
        zoom: {
          enabled: this.settings.zoom,
        },
        animations: {
          enabled: this.settings.animations,
        },
        toolbar: {
          show: true,
          download: true,
        },
      },
      dataLabels: {
        enabled: this.series.length > 1 || this.settings.isFunnel,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 0,
          color: '#000',
          opacity: 0.8,
        },
        formatter: (val: number, opt: any) => {
          let ret = []

          if (this.settings.isFunnel) {
            ret.push(`${this.labels[opt.dataPointIndex]}`)
          }

          ret.push(
            `${formatText(
              val,
              this.properties.metrics[opt.seriesIndex].format,
              !this.settings.disable_abbreviate,
            )}`,
          )

          return ret
        },
      },
      markers: {
        size: 5,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: number, opt: any) => {
            const total = opt.series.reduce(
              (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
              0,
            )

            let ret = [`${formatText(val, this.properties.metrics[opt.seriesIndex].format)}`]

            if (this.propertyCount.dimensions > 1) {
              ret.push(` ${formatText(val / total, 'percentage')}`)
            }

            return ret
          },
        },
      },
      plotOptions: {
        bar: {
          isFunnel: this.settings.isFunnel,
          horizontal: this.settings.isFunnel,
          distributed: this.settings.isFunnel,
          dataLabels: {
            position: stacked || this.settings.isFunnel ? 'center' : 'top',
            total: {
              enabled: stacked,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show:
          this.settings.legend
          ?? (this.propertyCount.dimensions > 1 || this.propertyCount.metrics > 1),
        position: 'bottom',
        horizontalAlign: 'right',
      },
      colors: this.colors,
      xaxis: {
        categories: this.labels,
      },
      yaxis: this.yaxis,
      stroke: {
        width: this.stroke,
      },
    }

    if (this.sub_type === 'radar') {
      delete ret.yaxis
      ret.stroke = {
        width: 2,
      }
    }

    return ret
  }

  /**
   * Prepare data for chart
   * @returns this
   */
  public process(check_only = false): this {
    if (!this.source_data) throw new ChartError('Source data not found', 'NO_DATA')

    this.total = 0

    // Check if compatible with chart
    if (
      (this.propertyCount.dimensions > 1 && this.propertyCount.metrics > 1)
      || this.propertyCount.dimensions > 2
    ) {
      throw new ChartError(
        'You can only have 1 dimensions & multiple metrics or 2 dimensions and 1 metric',
        'UNSUPPORTED_VALUES',
      )
    }

    // To avoid a messy chart, we need to make sure that we only up to 2 different data types
    let formats: string[] = []
    this.properties.metrics.forEach(metric => {
      formats.push(metric.format)
    })

    formats = [...new Set(formats)]

    if (formats.length > 2) {
      throw new ChartError(
        'You can not have more than 2 different data types in a chart',
        'UNSUPPORTED_VALUES',
      )
    }

    if (
      this.settings.isFunnel
      && this.propertyCount.metrics + this.propertyCount.dimensions !== 2
    ) {
      throw new ChartError(
        'Funnel Chart can only have 1 dimension and 1 metric',
        'UNSUPPORTED_VALUES',
      )
    }

    if (check_only) return this

    let last_format = ''
    // Prepare data
    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])
      this.total += val

      if (this.propertyCount.dimensions > 1) {
        // If there are more than 1 dimension, calculate the groups
        const group_label = formatText(
          item[this.properties.dimensions[1].name],
          this.properties.dimensions[1].format,
        )
        // Initialize group series
        if (!this.groups.includes(group_label)) {
          this.groups.push(group_label)
          this.series.push({ name: group_label, data: [] })
        }
      } else if (this.propertyCount.metrics > 1) {
        /**
         * If there are more than 1 metric, calculate the groups
         * This will also ensure that different data types will be
         * separated in different axis
         */
        this.properties.metrics.forEach((metric, idx) => {
          if (!this.groups.includes(metric.header)) {
            this.groups.push(metric.header)

            let type = this.sub_type

            let disallow_abbreviate = !this.settings.disable_abbreviate
            let yaxis: any = {
              opposite: false,
              labels: {
                show: false,
                align: 'center',
                formatter(value: number) {
                  return formatText(value, metric.format, disallow_abbreviate)
                },
              },
            }

            /**
             * By default, the first metric will be on the left axis,
             * subsequent in the same format will be disabled and
             * the rest will be on the right axis
             */
            if (!last_format) {
              yaxis.labels.show = !this.settings.isFunnel
            }

            // If the format is different, we need to put it on the right axis
            if (last_format && last_format !== metric.format) {
              yaxis.opposite = true
              yaxis.labels.show = !this.settings.isFunnel

              type = this.sub_type === 'bar' ? 'line' : 'bar'
            }

            // If line, we need to set the stroke width
            if (type === 'bar') {
              this.stroke.push(0)
            } else {
              this.stroke.push(4)
            }

            // Push the series
            if (this.sub_type !== 'radar') {
              this.series.push({ name: metric.header, data: [], type })
            } else {
              this.series.push({ name: metric.header, data: [] })
            }

            if (!last_format) {
              last_format = metric.format
            }

            // Register the yaxis seires
            if (this.sub_type !== 'radar') {
              this.yaxis.push(yaxis)
            }
          }
        })
      } else if (this.series.length === 0) {
        // If there is only 1 dimension & 1 metric format, init the series
        this.series.push({ name: '', data: [] })
        this.yaxis.push({ show: !this.settings.isFunnel })
      }

      // Format and push the label
      const label = formatText(
        item[this.properties.dimensions[0].name],
        this.properties.dimensions[0].format,
      )
      if (!this.labels.includes(label)) this.labels.push(label)
    })

    // Initialize color pool
    let color_pool = buildColorPool(this.labels.length ?? 1)
    if (this.groups.length > 0) {
      color_pool = buildColorPool(this.groups.length ?? 1)
    }

    // Initialize data with 0's
    this.series.forEach((item: any, idx: number) => {
      this.labels.forEach((_: string, l_idx: number) => {
        item.data.push(0)
        if (this.groups.length === 0) {
          this.colors.push(color_pool(l_idx))
        }
      })
      if (this.groups.length > 0) {
        this.colors.push(color_pool(idx))
      }
    })

    // Fill data
    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])

      this.total += val

      let group_index = 0

      const label = formatText(
        item[this.properties.dimensions[0].name],
        this.properties.dimensions[0].format,
      )

      const label_index = this.labels.indexOf(label)

      if (this.propertyCount.dimensions > 1) {
        const group_label = formatText(
          item[this.properties.dimensions[1].name],
          this.properties.dimensions[1].format,
        )
        group_index = this.groups.indexOf(group_label)
      } else if (this.propertyCount.metrics > 1) {
        this.properties.metrics.forEach((metric, idx) => {
          group_index = this.groups.indexOf(metric.header)
          this.series[group_index].data[label_index] += Number(
            item[this.properties.metrics[idx].name],
          )
        })

        return this
      }

      this.series[group_index].data[label_index] += val
    })

    if (this.settings.stacked) {
      // If stacked, max values needs to be manually set to the sum of the highest value
      let groups_max: any = []
      let max: any = []
      this.series.forEach((item: any, idx: number) => {
        if (!groups_max[item.type]) {
          groups_max[item.type] = []
        }

        groups_max[item.type].push(idx)
      })

      for (const key in groups_max) {
        if (!max[key]) max[key] = 0
        this.series[0].data.forEach((_: any, idx: number) => {
          let sum = 0
          groups_max[key].forEach((group_idx: number) => {
            sum += this.series[group_idx].data[idx]
          })

          if (sum > max[key]) {
            max[key] = sum
          }
        })
      }

      this.series.forEach((item: any, idx: number) => {
        this.yaxis[idx].max = Math.ceil(max[item.type])
      })
    }

    return this
  }
}
