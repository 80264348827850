
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import ChartBuilder from '@/models/Charts/ChartBuilder'
import ReportBuilderResponse from '@/models/interface/ReportBuilderResponse'
import ChartInterface from '@/models/Charts/ChartInterface'
import VueApexCharts from 'vue-apexcharts'
import DataTable from '@/components/DataTable/index.vue'
import Widget from '@/components/Widget/Widget.vue'
// @ts-ignore
import { VueFunnelGraph } from 'vue-funnel-graph-js'
import ReportBuilder from '@/models/ReportBuilder'
import ViewModel from '@/models/ViewModel'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import DatePickerDate from '@/models/DatePickerDate'
import moment from 'moment'
import numeral from 'numeral'
import { startCase, upperCase } from 'lodash'
import ChartError from '@/models/interface/ChartError'

@Component({
  components: {
    IconAction,
    VueApexCharts,
    VueFunnelGraph,
    Widget,
    DataTable,
  },
})
export default class ReportViewer extends ViewModel {
  @Ref() readonly containerRef!: HTMLDivElement

  @Prop({ default: '' })
  public title!: string

  @Prop({ required: true })
  public type!: string

  @Prop({ default: false })
  public showLoader!: boolean

  @Prop({ default: '' })
  public subType!: string

  @Prop({ default: null })
  public rawData!: any

  @Prop({ default: null })
  public report!: ReportBuilder

  @Prop({
    default: () => ({}),
  })
  public settings!: any

  public error_message: string | null = null

  public process_id: string | null = null

  public chart: ChartInterface | null = null

  private report_channel: any = null

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public show_chart: boolean = true

  public is_waiting: boolean = false

  @Prop({ default: false })
  public viewOnly!: boolean

  public fields: any = []

  public data: ReportBuilderResponse | null = null

  public local_type: string = ''

  public local_sub_type: string = ''

  public get container() {
    if (!this.containerRef || !this.containerRef.clientWidth) {
      return {
        width: 800,
        height: 300,
      }
    }

    return {
      width: this.containerRef.clientWidth,
      height: 300,
    }
  }

  public created() {
    this.local_type = this.type
    this.local_sub_type = this.subType
    if (this.rawData && this.rawData.result) {
      this.processResult(this.rawData)
      return
    }
    this.registerEvent()
    this.reload()
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
  }

  /**
   * Clear paste event to prevent triggering the event outside the page
   */
  private unregisterEvent() {
    if (!this.report_channel) return
    Vue.prototype.$echo.private(`user.${this.user!.id}`).stopListening('AsyncReportReady')
  }

  /**
   * Capture the paste event and get the data
   */
  private registerEvent() {
    if (this.viewOnly !== false) return
    this.report_channel = Vue.prototype.$echo
      .private(`user.${this.user!.id}`)
      .listen('AsyncReportReady', (e: any) => {
        const instance_id = getModule(SystemtModule)._uuid

        if (instance_id !== e.instance_id || this.process_id !== e.process_id) return

        if (!e.report_hash) {
          this.loading = false

          return
        }

        ReportBuilder.getReport(e.report_hash)
          .then(response => {
            this.$emit('update:rawData', response.data)
            this.processResult(response.data)
          })
          .catch(error => {
            this.loading = false
          })
      })
  }

  @Watch('rawData')
  public onRawDataChange(val: any) {
    this.processResult(val)
  }

  @Watch('subType')
  public onSubTypeChange(val: any) {
    setTimeout(() => {
      this.local_sub_type = val
      this.local_type = this.type

      if (this.rawData && this.rawData.result) {
        this.processResult(this.rawData)
      } else {
        this.reload()
      }
    }, 200)
  }

  public reload() {
    this.loading = true

    return this.report
      .runReport()
      .then((response: any) => {
        if (response.data.status === 'pending') {
          this.process_id = response.data.process_id
          this.is_waiting = true
          return {
            process_id: this.process_id,
            status: 'pending',
          }
        }
        this.processResult(response.data)
        this.$emit('update:rawData', response.data)
        return response.data
      })
      .catch(error => {
        this.loading = false
        return error
      })
  }

  public processResult(data: any) {
    this.is_waiting = false
    this.data = data
    this.fields = this.data?.fields.map((key: string) => ({
      key,
      sortable: true,
      show: true,
    }))
    this.error_message = null
    this.buildWidget()
  }

  public buildWidget() {
    this.show_chart = false
    if (!this.data) {
      this.error_message = 'No data'
      setTimeout(() => {
        this.show_chart = true
      }, 250)
      return
    }
    if (this.local_type === 'chart') {
      try {
        this.chart = ChartBuilder.getChart(this.local_sub_type, this.data, { stacked: false })
      } catch (e: any) {
        if (e instanceof ChartError) {
          this.error_message = e.message
        } else {
          this.error_message = 'Unknown error, please try again later or contact support team if the problem persists'
          // eslint-disable-next-line no-console
          console.log(e)
        }
      }
    }

    setTimeout(() => {
      this.show_chart = true
    }, 250)

    this.loading = false
  }

  // Table methods
  public getFieldHead(key: string) {
    for (const idx in this.data?.fields) {
      // @ts-ignore
      if (this.data.fields[idx] === key) {
        // @ts-ignore
        return this.data.headers[idx]
      }
    }

    return key
  }

  public getFieldFoot(key: string) {
    let ret: number | string = '-'

    if (key === this.data?.fields[0]) {
      return 'Totals'
    }

    let total = 'none'
    let format = 'text'

    for (const idx in this.data?.fields) {
      // @ts-ignore
      if (this.data.fields[idx] === key) {
        // @ts-ignore
        total = this.data.totals[idx]
        // @ts-ignore
        format = this.data.formats[idx]
        break
      }
    }

    if (total === 'sum') {
      ret = this.data?.result.reduce((acc: any, cur: any) => {
        acc += Number(cur[key])
        return acc
      }, 0)
    } else if (total === 'avg') {
      ret = this.data?.result.reduce((acc: any, cur: any) => {
        acc += Number(cur[key])
        return acc
      }, 0) / (this.data?.result.length ?? 1)
    } else if (total === 'avg_non_zero') {
      let count = 0
      ret = this.data?.result.reduce((acc: any, cur: any) => {
        if (cur[key] > 0) {
          acc += Number(cur[key])
          count++
        }
        return acc
      }, 0) / count
    } else if (total === 'min') {
      ret = this.data?.result.reduce((acc: any, cur: any) => {
        let val = cur[key]

        if (!val) return acc

        if (format === 'date') {
          val = moment(val).unix()
        } else if (typeof val === 'string') {
          val = Number(val)
        }

        if (acc === 0) {
          acc = val
        } else if (val < acc) {
          acc = val
        }
        return acc
      }, 0)
    } else if (total === 'max') {
      ret = this.data?.result.reduce((acc: any, cur: any) => {
        let val = cur[key]

        if (!val) return acc

        if (format === 'date') {
          val = moment(val).unix()
        } else if (typeof val === 'string') {
          val = Number(val)
        }

        if (acc === 0) {
          acc = val
        } else if (val > acc) {
          acc = val
        }
        return acc
      }, 0)
    }

    return this.getFormattedField(key, ret)
  }

  public run() {
    this.reload()
  }

  public getFormattedField(key: string, value: any) {
    let ret = value

    let format = 'text'
    for (const idx in this.data?.fields) {
      // @ts-ignore
      if (this.data.fields[idx] === key) {
        // @ts-ignore
        format = this.data.formats[idx]
        break
      }
    }

    if (ret === '') {
      return '-'
    }
    if (format === 'percentage') {
      ret = numeral(value).format('0.00%')
    } else if (format === 'percentage_rounded') {
      ret = numeral(value).format('0%')
    } else if (format === 'currency') {
      ret = numeral(value).format('$0,0.00')
    } else if (format === 'numeral' || format === 'number') {
      ret = numeral(value).format('0,0')
    } else if (format === 'date') {
      if (value == '' || value == '-' || !value) {
        return '-'
      }

      // If numeric, parse as unix timestamp
      if (typeof value === 'number') {
        ret = moment.unix(value).format('MMMM, Do YYYY')
      } else {
        ret = moment(value).format('MMMM, Do YYYY')
      }
    } else if (format === 'month') {
      if (value == '' || value == '-' || !value) {
        return '-'
      }

      // If numeric, parse as unix timestamp
      if (typeof value === 'number') {
        ret = moment.unix(value).format('MMMM, YYYY')
      } else {
        ret = moment(value).format('MMMM, YYYY')
      }
    } else if (format === 'date_time') {
      if (value == 0) {
        return '-'
      }

      // If numeric, parse as unix timestamp
      if (typeof value === 'number') {
        ret = moment.unix(value).format('MMMM, Do YYYY h:mm:ss a')
      } else {
        ret = moment(value).format('MMMM, Do YYYY h:mm:ss a')
      }
    } else if (format === 'time') {
      if (value == 0) {
        return '-'
      }

      // If numeric, parse as unix timestamp
      if (typeof value === 'number') {
        ret = moment.unix(value).format('h:mm:ss a')
      } else {
        ret = moment(value).format('h:mm:ss a')
      }
    } else if (format === 'decimal') {
      ret = numeral(value).format('0,0.00')
    } else if (format === 'abbreviate') {
      ret = numeral(value).format('0.00a')
    } else if (format === 'capitalize') {
      ret = startCase(value)
    } else if (format === 'uppercase') {
      ret = value.toUpperCase()
    }

    return ret
  }
}
