
import Company from '@/models/Company'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'
import Publisher from '@/models/Publisher'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import { DeviceOptions } from '@/models/interface/Common'
import SelectOption from '@/models/interface/SelectOption'
import { clone } from 'lodash'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import DMASOptions from '@/data/dma_options'
import StateOptions from '@/data/state_options'
import Api from '@/models/Api'

@Component
export default class ReportBuilderFilter extends ViewModel {
  public selected_filter_option: any = null

  @Prop({ default: () => [] })
  public activeOptions!: string[]

  @Prop({ default: () => [] })
  public value!: any[]

  @Prop()
  public properties!: any[]

  @Prop()
  public ref_properties!: any[]

  @Prop({ default: [] })
  public filters!: any

  @Prop()
  public start!: any

  @Prop()
  public end!: any

  @Watch('selected_filter_option')
  public selectedFilterChange(val: any) {
    if (val && !this.blockWatch) {
      this.dataLoader()
    }
  }

  public blockWatch: boolean = false

  public loading_options: boolean = false

  public options: any = []

  public mounted() {
    this.initFilters()
    setTimeout(() => {
      this.restoreValues()
    }, 1000)
  }

  @Watch('filters')
  public filtersChange() {
    this.initFilters()
  }

  public initFilters() {
    this.options = clone(this.filters)
  }

  public search_for: any = null

  public search_for_option: any = null

  public search_for_option_value: any = null

  public selected_options: any = {}

  public selected_filters: any = {}

  public loaded_options: any = []

  // used for forcing update in computed
  public trigger_update: any = 0

  @Watch('search_for_option')
  public searchOptionChange() {
    if (!this.loading && !this.blockWatch) {
      this.dataLoader()
    }
  }

  @Watch('selected_option_list')
  public selectedOnChange() {
    this.formatValue()
  }

  public get computed_options() {
    let check: any = {
      station:
        (!this.user.isStation && this.user.company.has_stations)
        || this.user.company.root,
      sales_reps:
        this.user.company.root && this.user.company.type != 'advertiser',
      inventory:
        this.user.company.root && this.user.company.type != 'advertiser',
    }

    let opts = this.options.filter((o: SelectOption) => {
      if (this.search_for && this.search_for.length) {
        if (check.hasOwnProperty(o.value)) {
          return (
            o.name.toLowerCase().includes(this.search_for.toLowerCase())
            && check[o.value]
          )
        }
        return o.name.toLowerCase().includes(this.search_for.toLowerCase())
      }

      return o
    })

    return opts.map(o => {
      o.r_id = `filter.${o.value}`
      return o
    })
  }

  public get incompatible_parent_message() {
    if (!this.selected_filter_options_incompatible) return false
    return this.disabledDescription(this.activeFilterSettings)
  }

  public get selected_filter_options_incompatible() {
    if (!this.activeFilterSettings) return false
    return this.isDisabled(this.activeFilterSettings)
  }

  public get selected_option_list() {
    this.trigger_update // eslint-disable-line
    return Object.entries(this.selected_options)
  }

  public get selected_options_count() {
    this.trigger_update // eslint-disable-line

    let entries = Object.entries(this.selected_options)

    return entries.reduce((acc: any, cur: any) => {
      acc += cur[1].length
      return acc
    }, 0)
  }

  public async restoreValues() {
    this.blockWatch = true
    this.loading_options = true

    let search_for_option: any = null

    const local_filter = (o: any) => {
      if (!this.search_for_option) {
        return true
      }
      let name = o.name

      if (o.full_name) {
        name = o.full_name
      }

      let value = o.value

      if (typeof o.value === 'number') {
        value = o.value.toString()
      }

      return (
        name.includes(this.search_for_option)
        || value.includes(this.search_for_option)
      )
    }
    try {
      let search_for: any = null
      let operations: any = {
        company: async () =>
          Company.searchOptions({ value: search_for_option }),
        media_plan: async () =>
          MediaPlan.searchOptions({ value: search_for_option }),
        media_plan_item: async () =>
          MediaPlanItem.searchOptions({ value: search_for_option }),
        inventory: async () =>
          Publisher.module.searchOptions({ value: search_for_option }),
        user: async () => User.searchOptions({ value: search_for_option }),
        order_type: async () =>
          [
            new SelectOption('Cash & Trade', 'All'),
            new SelectOption('Cash', 'Cash'),
            new SelectOption('Trade', 'Trade'),
          ].filter(local_filter),
        device: async () => DeviceOptions.filter(local_filter),
        dma: async () => DMASOptions.filter(local_filter),
        state: async () => StateOptions.filter(local_filter),
        custom: async () =>
          this.filters
            .find((f: any) => f.value === search_for)
            .options.filter(local_filter),
        media_ocean_property: (values: any) => [
          {
            name: values,
            value: values,
          },
        ],
      }

      const user_selected_filter_values = JSON.parse(JSON.stringify(this.value))

      let item: any = null
      for (item of Object.keys(user_selected_filter_values)) {
        const value = user_selected_filter_values[item]
        const filter_option = this.filters.find((f: any) => f.value === item)
        if (!value || !value.length) {
          // eslint-disable-next-line no-continue
          continue
        }

        for (let v of value) {
          if (filter_option && operations[filter_option.type]) {
            search_for_option = v
            if (filter_option.type === 'custom') {
              search_for = item
            }

            const response = await operations[filter_option.type](v)

            response.forEach((res: any) => {
              if (!this.selected_options) {
                this.selected_options = {}
              }
              if (!this.selected_options[item]) {
                this.selected_options[item] = []
              }

              let existing_index = this.selected_options[item].findIndex(
                (o: SelectOption) => o.value === v, // it was before: o.value === res.value // the correct way is by using the const value.*, so we are looping the value and finding the selected saved filter
              )

              // === prevent duplications
              let exists = this.selected_options[item].some(
                (opt: any) => opt.value === res.value,
              )
              // ===
              if (existing_index < 0 && !exists) {
                this.selected_options[item].push(res)
              }

              this.trigger_update++
            })
          }
        }
      }
    } catch (error) {
      this.blockWatch = false
      this.loading_options = false
    } finally {
      setTimeout(() => {
        this.blockWatch = false
        this.loading_options = false
      }, 1200)
    }
  }

  public normalizeName(property: any) {
    if (property.full_name) return property.full_name
    return property.name
  }

  public select_property(opt: any) {
    if (!this.isDisabled(opt)) {
      this.search_for_option = ''
      this.selected_filter_option = opt.value
    }
  }

  public isDisabled(option: any): boolean {
    if (
      this.selected_options.length >= 10
      && !this.selected_options.includes(option.value)
    ) {
      return true
    }

    if (option.incompatible && option.incompatible.length > 0) {
      return option.incompatible.some((o: any) =>
        this.activeOptions.includes(o))
    }

    return false
  }

  public disabledDescription(option: any): string {
    if (
      this.selected_options.length >= 10
      && !this.selected_options.includes(option.value)
    ) {
      return "You can't select more than 10 options"
    }

    const allOptions = [
      ...this.properties,
      ...this.ref_properties,
      ...this.filters,
    ]
    if (option.incompatible && option.incompatible.length > 0) {
      let active_incompatible = option.incompatible.filter((o: any) =>
        this.activeOptions.includes(o))

      if (active_incompatible && active_incompatible.length > 0) {
        let incompatible: any[] = []

        allOptions.forEach((o: any) => {
          if (o.options && o.options.length > 0) {
            o.options.forEach((opt: any) => {
              if (active_incompatible.includes(opt.r_id ?? opt.value)) {
                incompatible.push(opt.label ? opt.label : opt.name)
              }
            })
          } else if (active_incompatible.includes(o.r_id ?? o.value)) {
            incompatible.push(o.label ? o.label : o.name)
          }
        })

        return `Incompatible with ${incompatible.join(', ')}`
      }
    }

    return ''
  }

  public getSelectedName(v: string) {
    return this.options.find((o: any) => o.value === v)?.name
  }

  public removeOption(key: string, value: string) {
    this.selected_options[key] = this.selected_options[key].filter(
      d => d.value !== value,
    )
    if (!this.selected_options[key] || !this.selected_options[key].length) {
      delete this.selected_options[key]
    }
    this.trigger_update++
  }

  public selectOption(opt: any) {
    if (this.selected_filter_options_incompatible && !this.blockWatch) return
    if (!this.selected_options) {
      this.selected_options = {}
    }
    if (!this.selected_options[this.selected_filter_option]) {
      this.selected_options[this.selected_filter_option] = []
    }

    let existing_index = this.selected_options[
      this.selected_filter_option
    ].findIndex((o: SelectOption) => o.value === opt.value)

    if (existing_index < 0) {
      this.selected_options[this.selected_filter_option].push(opt)
    }

    this.trigger_update++
  }

  public get activeFilterSettings() {
    return this.filters.find(
      (f: any) => f.value === this.selected_filter_option,
    )
  }

  public dataLoader() {
    if (this.blockWatch) return
    this.loading_options = true

    let local_filter = (o: any) => {
      if (!this.search_for_option) {
        return true
      }
      let name = o.name

      if (o.full_name) {
        name = o.full_name
      }

      name = name.toLowerCase()

      let value = o.value

      if (typeof o.value === 'number') {
        value = o.value.toString()
      }

      value = value.toLowerCase()

      return (
        name.includes(this.search_for_option.toLowerCase())
        || value.includes(this.search_for_option.toLowerCase())
      )
    }

    const api = new Api()

    let op: any = {
      company: async () =>
        Company.searchOptions({
          search: this.search_for_option,
          type: this.activeFilterSettings.sub_type,
        }),
      media_plan: async () =>
        MediaPlan.searchOptions({
          search: this.search_for_option,
          agency_id: this.selected_options.agency
            ? this.selected_options.agency.map((a: any) => a.value)
            : null,
          advertiser_id: this.selected_options.advertiser
            ? this.selected_options.advertiser.map((a: any) => a.value)
            : null,
          station_id: this.selected_options.station
            ? this.selected_options.station.map((a: any) => a.value)
            : null,
        }),
      media_plan_item: async () =>
        MediaPlanItem.searchOptions({
          search: this.search_for_option,
          agency_id: this.selected_options.agency
            ? this.selected_options.agency.map((a: any) => a.value)
            : null,
          advertiser_id: this.selected_options.advertiser
            ? this.selected_options.advertiser.map((a: any) => a.value)
            : null,
          station_id: this.selected_options.station
            ? this.selected_options.station.map((a: any) => a.value)
            : null,
          media_plan_id: this.selected_options.media_plan
            ? this.selected_options.media_plan.map((a: any) => a.value)
            : null,
        }),
      inventory: async () =>
        Publisher.module.searchOptions({
          search: this.search_for_option,
        }),
      user: async () =>
        User.searchOptions({
          search: this.search_for_option,
          type: this.activeFilterSettings.sub_type,
        }),
      order_type: async () =>
        [
          new SelectOption('Cash & Trade', 'All'),
          new SelectOption('Cash', 'Cash'),
          new SelectOption('Trade', 'Trade'),
        ].filter(local_filter),
      device: async () => DeviceOptions.filter(local_filter),
      dma: async () => DMASOptions.filter(local_filter),
      state: async () => StateOptions.filter(local_filter),
      custom: async () =>
        this.activeFilterSettings.options.filter(local_filter),
      media_ocean_property: async () => {
        // Add Aditional filters
        let filters: any = {
          agency_product_code: this.selected_options.agency_product_code
            ? this.selected_options.agency_product_code.map((a: any) => a.value)
            : null,
          product_name: this.selected_options.product_name
            ? this.selected_options.product_name.map((a: any) => a.value)
            : null,
          agency_advertiser_code: this.selected_options.agency_advertiser_code
            ? this.selected_options.agency_advertiser_code.map(
              (a: any) => a.value,
            )
            : null,
          agency_estimate_code: this.selected_options.agency_estimate_code
            ? this.selected_options.agency_estimate_code.map(
              (a: any) => a.value,
            )
            : null,
        }
        if (this.activeFilterSettings.sub_type === 'agency_advertiser_code') {
          filters = {
            agency_advertiser_code: filters.agency_advertiser_code,
          }
        } else if (
          this.activeFilterSettings.sub_type === 'agency_product_code'
        ) {
          filters = {
            agency_advertiser_code: filters.agency_advertiser_code,
          }
        } else if (this.activeFilterSettings.sub_type === 'product_name') {
          filters = {
            agency_advertiser_code: filters.agency_advertiser_code,
            agency_product_code: filters.agency_product_code,
          }
        } else if (
          this.activeFilterSettings.sub_type === 'agency_estimate_code'
        ) {
          filters = {
            agency_advertiser_code: filters.agency_advertiser_code,
            agency_product_code: filters.agency_product_code,
            product_name: filters.product_name,
          }
        }

        return api.post('media_ocean_invoice/properties/search/option', {
          field: this.activeFilterSettings.sub_type,
          filters,
          start: this.start,
          end: this.end,
          agency_ids: this.selected_options.agency
            ? this.selected_options.agency.map((a: any) => a.value)
            : null,
          advertiser_ids: this.selected_options.advertiser
            ? this.selected_options.advertiser.map((a: any) => a.value)
            : null,
          media_plan_ids: this.selected_options.media_plan
            ? this.selected_options.media_plan.map((a: any) => a.value)
            : null,
          search: this.search_for_option,
        })
      },
    }

    op[this.activeFilterSettings.type]().then((response: any) => {
      if (this.activeFilterSettings.type === 'media_ocean_property') {
        this.loaded_options = response.data.result.options.map(
          (o: any) => new SelectOption(o.name, o.value),
        )
      } else {
        this.loaded_options = response
      }

      this.loading_options = false
    })
  }

  public formatValue() {
    let ret: any = {}
    Object.entries(this.selected_options).forEach((o: any) => {
      let key = o[0]
      let values = o[1].map((v: any) => v.value)

      ret[key] = values
    })

    this.$emit('input', ret)
  }
}
