import ReportBuilderResponse from '../interface/ReportBuilderResponse'

export default class ChartBuilderHelper {
  protected source_data?: ReportBuilderResponse

  protected get propertyCount() {
    let dimensions = 0
    let metrics = 0

    this.source_data?.fields.forEach(field => {
      if (field.includes('dimension')) {
        dimensions++
      } else if (field.includes('metric')) {
        metrics++
      }
    })

    return {
      dimensions,
      metrics,
    }
  }

  public get properties() {
    let dimensions: any[] = []
    let metrics: any[] = []

    this.source_data?.fields.forEach((field, idx) => {
      const prop = {
        name: field,
        header: this.source_data?.headers[idx],
        format: this.source_data?.formats[idx],
      }
      if (field.includes('dimension')) {
        dimensions.push(prop)
      } else if (field.includes('metric')) {
        metrics.push(prop)
      }
    })

    return {
      dimensions,
      metrics,
    }
  }
}
