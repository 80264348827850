import ChartInterface from './ChartInterface'
import ChartBuilderHelper from './ChartBuilderHelper'
import { buildColorPool, formatText } from '../Util'
import ReportBuilderResponse from '../interface/ReportBuilderResponse'
import ChartError from '../interface/ChartError'

export default class PieChart extends ChartBuilderHelper implements ChartInterface {
  public static create(_: string, data: ReportBuilderResponse, settings: any = {}, check_only = false): ChartInterface {
    const ret = new this()

    ret.source_data = data
    ret.settings = { ...ret.settings, ...settings }
    // Ignore Type, should always be pie
    // ret.sub_type = type

    ret.process(check_only)

    return ret
  }

  public static isCompatible(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
  ): boolean {
    this.create(type, data, settings, true)

    return true
  }

  protected settings: any = {
    zoom: false,
    toolbar: false,
    animations: true,
    legend: false,
    disable_abbreviate: false,
  }

  public labels: string[] = []

  public type: string = 'apex'

  public sub_type?: string = 'pie'

  public series: any[] = []

  public groups: string[] = []

  public colors: string[] = []

  public total: number = 0

  public get options(): any {
    return {
      chart: {
        type: this.sub_type,
        height: 'auto',
        zoom: {
          enabled: this.settings.zoom,
        },
        animations: {
          enabled: this.settings.animations,
        },
        toolbar: {
          show: true,
          download: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number, opt: any) => [
          `${this.labels[opt.seriesIndex]}`,
          formatText(
            this.series[opt.seriesIndex],
            this.properties.metrics[0].format,
            !this.settings.disable_abbreviate,
          ),
        ],
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: number) => [
            `${formatText(
              val,
              this.properties.metrics[0].format,
            )}`,
            ` ${formatText(val / this.total, 'percentage')}`,
          ],
        },
      },
      legend: {
        show: this.settings.legend,
        position: 'bottom',
      },
      colors: this.colors,
      labels: this.labels,
    }
  }

  public process(check_only = false): this {
    if (!this.source_data) throw new ChartError('Source data not found', 'NO_DATA')

    this.total = 0

    const color_pool = buildColorPool(this.source_data.result.length)

    if (this.propertyCount.dimensions > 1 || this.propertyCount.metrics > 1) {
      throw new ChartError('Pie chart must have only 1 dimension and 1 metric', 'UNSUPPORTED_VALUES')
    }

    if (check_only) return this

    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])
      this.total += val
      this.series.push(val)
      this.labels.push(
        formatText(item[this.properties.dimensions[0].name], this.properties.dimensions[0].format),
      )
      this.colors.push(color_pool(idx))
    })

    return this
  }
}
