import ChartInterface from './ChartInterface'
import ChartBuilderHelper from './ChartBuilderHelper'
import { buildColorPool, formatText } from '../Util'
import ReportBuilderResponse from '../interface/ReportBuilderResponse'
import ChartError from '../interface/ChartError'

export default class TreemapChart extends ChartBuilderHelper implements ChartInterface {
  public static create(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
    check_only = false,
  ): ChartInterface {
    const ret = new this()

    ret.source_data = data
    ret.settings = { ...ret.settings, ...settings }
    ret.sub_type = type

    ret.process()

    return ret
  }

  public static isCompatible(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
  ): boolean {
    this.create(type, data, settings, true)

    return true
  }

  protected settings: any = {
    zoom: false,
    toolbar: false,
    animations: true,
    legend: false,
    disable_abbreviate: false,
  }

  public labels: string[] = []

  public groups: string[] = []

  public type: string = 'apex'

  public sub_type?: string = 'treemap'

  public series: any[] = []

  public colors: string[] = []

  public total: number = 0

  public get options(): any {
    return {
      chart: {
        type: this.sub_type,
        height: 'auto',
        zoom: {
          enabled: this.settings.zoom,
        },
        animations: {
          enabled: this.settings.animations,
        },
        toolbar: {
          show: true,
          download: true,
        },
        export: {
          csv: false,
        },
      },
      dataLabels: {
        formatter: (val: number, opt: any) => {
          let group = ''
          if (this.groups.length > 1) {
            group = `, ${this.groups[opt.seriesIndex]}`
          }
          return [
            `${this.labels[opt.dataPointIndex]}${group}`,
            formatText(
              opt.value,
              this.properties.metrics[0].format,
              !this.settings.disable_abbreviate,
            ),
          ]
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: number) => [
            `${formatText(val, this.properties.metrics[0].format)}`,
            ` ${formatText(val / this.total, 'percentage')}`,
          ],
        },
      },
      legend: {
        show: this.settings.legend,
        position: 'bottom',
      },
      colors: this.colors,
      labels: this.labels,
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
          dataLabels: {
            format: 'scale',
          },
        },
        heatmap: {
          enableShades: false,
        },
      },
    }
  }

  public process(check_only = false): this {
    if (!this.source_data) throw new ChartError('Source data not found', 'NO_DATA')

    this.total = 0

    // Check if compatible with chart
    if (
      (this.propertyCount.dimensions > 1 && this.propertyCount.metrics > 1)
      || this.propertyCount.dimensions > 2
    ) {
      throw new ChartError(
        'You can only have 1 dimensions & multiple metrics or 2 dimensions and 1 metric',
        'UNSUPPORTED_VALUES',
      )
    }

    if (check_only) return this

    // Prepare data
    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])
      this.total += val

      if (this.propertyCount.dimensions > 1) {
        // If there are more than 1 dimension, calculate the groups
        const group_label = formatText(
          item[this.properties.dimensions[1].name],
          this.properties.dimensions[1].format,
        )
        // Initialize group series
        if (!this.groups.includes(group_label)) {
          this.groups.push(group_label)
          this.series.push({ name: group_label, data: [] })
        }
      } else if (this.propertyCount.metrics > 1) {
        /**
         * If there are more than 1 metric, calculate the groups
         * This will also ensure that different data types will be
         * separated in different axis
         */
        this.properties.metrics.forEach((metric, idx) => {
          if (!this.groups.includes(metric.header)) {
            this.groups.push(metric.header)

            // Push the series
            this.series.push({ name: metric.header, data: [] })
          }
        })
      } else if (this.series.length === 0) {
        // If there is only 1 dimension & 1 metric format, init the series
        this.series.push({ name: this.properties.dimensions[0].header, data: [] })
      }

      // Format and push the label
      const label = formatText(
        item[this.properties.dimensions[0].name],
        this.properties.dimensions[0].format,
      )
      if (!this.labels.includes(label)) this.labels.push(label)
    })

    // Initialize color pool
    if (this.groups.length > 0) {
      const color_pool = buildColorPool(this.groups.length ?? 1)
      this.groups.forEach((group: string, idx: number) => {
        this.colors.push(color_pool(idx))
      })
    } else {
      const color_pool = buildColorPool(this.labels.length ?? 1)
      this.labels.forEach((item: any, idx: number) => {
        this.colors.push(color_pool(idx))
      })
    }
    // Initialize data with 0's
    this.series.forEach((item: any, idx: number) => {
      this.labels.forEach((label: string) => {
        item.data.push({ x: label, y: 0 })
      })
    })

    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])
      this.total += val

      let group_index = 0
      let label_index = 0

      const label = formatText(
        item[this.properties.dimensions[0].name],
        this.properties.dimensions[0].format,
      )

      label_index = this.labels.indexOf(label)

      if (this.propertyCount.dimensions > 1) {
        const group_label = formatText(
          item[this.properties.dimensions[1].name],
          this.properties.dimensions[1].format,
        )
        group_index = this.groups.indexOf(group_label)
      } else if (this.propertyCount.metrics > 1) {
        this.properties.metrics.forEach((metric, idx) => {
          group_index = this.groups.indexOf(metric.header)

          this.series[group_index].data[label_index].y += Number(
            item[this.properties.metrics[idx].name],
          )
        })

        return this
      } else {
        group_index = 0
      }

      this.series[group_index].data[label_index].y += val
    })

    return this
  }
}
