import ChartError from '../interface/ChartError'
import ReportBuilderResponse from '../interface/ReportBuilderResponse'
import BarChart from './BarChart'
import ChartInterface from './ChartInterface'
import GraphFunnelChart from './GraphFunnelChart'
import PieChart from './PieChart'
import RadialBarChart from './RadialBarChart'
import TreemapChart from './TreemapChart'

export default class ChartBuilder {
  public static getChart(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
  ): ChartInterface {
    let ret = null
    if (type === 'pie') {
      ret = PieChart.create(type, data, settings)
    } else if (['bar', 'line', 'area', 'funnel', 'radar'].includes(type)) {
      ret = BarChart.create(type, data, settings)
    } else if (type === 'radialBar' || type === 'gauge') {
      ret = RadialBarChart.create(type, data, settings)
    } else if (type === 'treemap' || type === 'heatmap') {
      ret = TreemapChart.create(type, data, settings)
    }

    if (!ret) throw new ChartError('Chart type not found', 'UNKOWN_TYPE')

    return ret
  }

  public static isCompatible(type: string, data: ReportBuilderResponse) {
    let ret = false
    if (type === 'pie') {
      ret = PieChart.isCompatible(type, data)
    } else if (['bar', 'line', 'area', 'funnel', 'radar'].includes(type)) {
      ret = BarChart.isCompatible(type, data)
    } else if (type === 'radialBar' || type === 'gauge') {
      ret = RadialBarChart.isCompatible(type, data)
    } else if (type === 'treemap' || type === 'heatmap') {
      ret = TreemapChart.isCompatible(type, data)
    }

    if (!ret) throw new ChartError('Chart type not found', 'UNKOWN_TYPE')

    return ret
  }
}
