import ChartInterface from './ChartInterface'
import ChartBuilderHelper from './ChartBuilderHelper'
import { buildColorPool, formatText } from '../Util'
import ReportBuilderResponse from '../interface/ReportBuilderResponse'
import ChartError from '../interface/ChartError'

export default class RadialBarChart extends ChartBuilderHelper implements ChartInterface {
  public static create(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
    check_only = false,
  ): ChartInterface {
    const ret = new this()

    ret.source_data = data
    ret.settings = { ...ret.settings, ...settings }
    // Ignore Type, should always be radialBar
    // ret.sub_type = type
    if (type === 'gauge') {
      ret.settings.startAngle = -135
      ret.settings.endAngle = 135
      ret.settings.legend = false
    }

    ret.process(check_only)

    return ret
  }

  public static isCompatible(
    type: string,
    data: ReportBuilderResponse,
    settings: any = {},
  ): boolean {
    this.create(type, data, settings, true)

    return true
  }

  protected settings: any = {
    zoom: false,
    toolbar: false,
    animations: true,
    legend: true,
    disable_abbreviate: false,
    offsetY: -20,
    startAngle: 0,
    endAngle: 270,
  }

  public labels: string[] = []

  public groups: string[] = []

  public type: string = 'apex'

  public sub_type?: string = 'radialBar'

  public series: any[] = []

  public series_raw: any[] = []

  public colors: string[] = []

  public total: number = 0

  public get options(): any {
    return {
      chart: {
        offsetY: this.settings.offsetY,
        type: this.sub_type,
        height: 'auto',
        zoom: {
          enabled: this.settings.zoom,
        },
        animations: {
          enabled: this.settings.animations,
        },
        toolbar: {
          show: true,
          download: true,
        },
      },
      dataLabels: {
        formatter: (val: number, opt: any) => [
          `${this.labels[opt.seriesIndex]}`,
          formatText(this.series[opt.seriesIndex], this.properties.metrics[0].format),
        ],
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: number, opt: any) => [
            `${formatText(this.series_raw[opt.seriesIndex], this.properties.metrics[0].format)}`,
            ` ${formatText(val / 100, 'percentage')}`,
          ],
        },
      },
      legend: {
        show: this.settings.legend,
        formatter: (val: number, opt: any) => [
          `${this.labels[opt.seriesIndex]}:`,
          `${formatText(
            this.series_raw[opt.seriesIndex],
            this.properties.metrics[0].format,
            !this.settings.disable_abbreviate,
          )}`,
        ],
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
      colors: this.colors,
      labels: this.labels,
      plotOptions: {
        radialBar: {
          startAngle: this.settings.startAngle,
          endAngle: this.settings.endAngle,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: this.settings.startAngle < 0,
            },
            value: {
              show: this.settings.startAngle < 0,
            },
          },
        },
      },
    }
  }

  public process(check_only = false): this {
    if (!this.source_data) throw new ChartError('Source data not found', 'NO_DATA')

    this.total = 0

    const color_pool = buildColorPool(this.source_data.result.length)

    if (this.propertyCount.dimensions > 1 || this.propertyCount.metrics !== 2) {
      throw new ChartError(
        'Radial chart must have only 1 dimension and 2 metrics',
        'UNSUPPORTED_VALUES',
      )
    }

    if (check_only) return this

    this.total = this.source_data.result.reduce(
      (acc: number, item: any) => (acc += Number(item[this.properties.metrics[0].name])),
      0,
    )

    this.source_data.result.forEach((item: any, idx: number) => {
      const val = Number(item[this.properties.metrics[0].name])
      const goal = Number(item[this.properties.metrics[1].name])
      let percentage = (val / goal) * 100

      this.total += val
      this.series.push(percentage.toFixed(2))
      this.series_raw.push(val)

      let label = this.properties.metrics[0].header
      if (this.propertyCount.dimensions > 0) {
        label = formatText(
          item[this.properties.dimensions[0].name],
          this.properties.dimensions[0].format,
        )
      }
      this.labels.push(label)
      this.colors.push(color_pool(idx))
    })

    return this
  }
}
