
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
// @ts-ignore
import draggable from 'vuedraggable'

@Component({
  components: {
    FormInput,
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    draggable,
  },
})
export default class ReportBuilder extends ViewModel {
  @Prop({ default: () => [] })
  public activeOptions!: string[]

  @Prop()
  public properties!: any[]

  @Prop()
  public ref_properties!: any[]

  @Prop()
  public filter_options!: any[]

  @Prop()
  public filter_selected!: any

  @Prop()
  public value!: any

  @Prop()
  public label!: any

  public search_for: any = ''

  public selected_options: any = []

  public mounted() {
    this.selected_options = this.value ?? []
  }

  @Watch('selected_options')
  public selectedOptionsChange(val) {
    this.$emit('input', val)
  }

  @Watch('value')
  public valueChange(val) {
    if (val !== this.selected_options) {
      this.selected_options = val ?? []
    }
  }

  public property_index: number = 0

  public get options() {
    if (!this.properties) return false
    return this.properties.filter(d => {
      if (this.search_for && this.search_for.length) {
        return (
          d.name.toLowerCase().includes(this.search_for.toLowerCase())
          || (d.options
            && d.options.some(
              o =>
                o.description.toLowerCase().includes(this.search_for.toLowerCase())
                || o.name.toLowerCase().includes(this.search_for.toLowerCase()),
            ))
        )
      }
      return d
    })
  }

  public get selected_option_list() {
    let selected: any[] = []
    let ret: any[] = []

    this.options.forEach((option: any) => {
      if (option.options && option.options.length > 0) {
        option.options.forEach((opt: any) => {
          if (this.selected_options.includes(opt.value)) {
            selected.push(opt)
          }
        })
      } else if (this.selected_options.includes(option.value)) {
        selected.push(option)
      }
    })

    // Sort selected options based on the order of selected_options
    this.selected_options.forEach((opt: any) => {
      let index = selected.findIndex(s => s.value === opt)
      if (index > -1) {
        ret.push(selected[index])
      }
    })

    return ret
  }

  public set selected_option_list(value: any[]) {
    this.selected_options = value.map((opt: any) => opt.value)
  }

  public get property_options_list() {
    if (!this.properties[this.property_index] || !this.properties[this.property_index].options) {
      return []
    }
    let ret = this.properties[this.property_index].options

    if (this.search_for && this.search_for.length) {
      ret = ret.filter(
        (d: any) =>
          d.description.toLowerCase().includes(this.search_for.toLowerCase())
          || d.name.toLowerCase().includes(this.search_for.toLowerCase()),
      )
    }

    return ret
  }

  /**
   * Get selected filters
   *
   * Used to check if the selected filters contains incompatible option
   */
  public get selected_filters() {
    let selected = this.filter_options.filter(o => this.activeOptions.includes(o.r_id))
    return selected
  }

  public isDisabled(option: any): boolean {
    if (this.selected_options.length >= 10 && !this.selected_options.includes(option.value)) {
      return true
    }

    /** Check for incompatible filters */
    let incompatible_filters = this.selected_filters.some(f =>
      f.incompatible.includes(option.value))

    if (incompatible_filters) return true

    if (option.incompatible && option.incompatible.length > 0) {
      return option.incompatible.some((o: any) => this.activeOptions.includes(o))
    }

    /** Check for required options */
    if (option.requires && option.requires.length > 0) {
      return !option.requires.some((o: any) => this.selected_options.includes(o))
    }

    return false
  }

  public disabledDescription(option: any): string {
    if (this.selected_options.length >= 10 && !this.selected_options.includes(option.value)) {
      return "You can't select more than 10 options"
    }

    const allOptions = [...this.properties, ...this.ref_properties, ...this.filter_options]
    if (option.incompatible && option.incompatible.length > 0) {
      let active_incompatible = option.incompatible.filter((o: any) =>
        this.activeOptions.includes(o))

      /** Check for incompatible filters */
      let incompatible_filters = this.selected_filters
        .filter(f => f.incompatible.includes(option.value))
        .flatMap(f => f.value)

      if (incompatible_filters.length) {
        active_incompatible = [...active_incompatible, ...incompatible_filters]
      }

      if (active_incompatible && active_incompatible.length > 0) {
        let incompatible: any[] = []
        allOptions.forEach((o: any) => {
          if (o.options && o.options.length > 0) {
            o.options.forEach((opt: any) => {
              if (active_incompatible.includes(opt.value)) {
                incompatible.push(opt.label ? opt.label : opt.name)
              }
            })
          } else if (active_incompatible.includes(o.value)) {
            incompatible.push(o.label ? o.label : o.name)
          }
        })

        return `Incompatible with ${incompatible.join(', ')}`
      }
    }

    // Check required
    if (option.requires && option.requires.length > 0) {
      let requires: any[] = []
      allOptions.forEach((o: any) => {
        if (o.options && o.options.length > 0) {
          o.options.forEach((opt: any) => {
            if (option.requires.includes(opt.value)) {
              requires.push(opt.label ? opt.label : opt.name)
            }
          })
        } else if (option.requires.includes(o.value)) {
          requires.push(o.label ? o.label : o.name)
        }
      })

      return `Requires ${requires.join(', ')}`
    }

    return ''
  }

  public removeOptions(key: string) {
    let index = this.selected_options.indexOf(key)

    if (index > -1) {
      this.selected_options.splice(index, 1)
    }
  }

  public selecteProperty(option: any) {
    if (option.options && option.options.length > 0) {
      let inx = this.properties.findIndex(p => p.value === option.value)
      Vue.set(this, 'property_index', inx)
    } else {
      let selected = this.selected_options.some(s => s === option.value)
      if (!selected) {
        this.selected_options.push(option.value)
      }
    }
  }
}
